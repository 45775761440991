.index {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.links {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.link {
  border-top: var(--primary-color) 0.25rem solid;
  background: rgba(255, 255, 255, 0.25);
  color: #fff;
  border-radius: 0 0 0.25rem 0.25rem;
  box-shadow: var(--elev3);
  cursor: pointer;
  user-select: none;
  text-decoration: none;
  padding: 1.5rem;
  font-weight: bold;
}
.space-between {
  justify-content: space-between;
}
.action-cections {
  text-align: center;
  display: flex;
  flex-direction: column;
}

.action-cections button:first-child {
  margin-bottom: 20px;
}
.home-heading {
  font-size: 32px;
}
.app-slogan {
  text-align: justify;
}

.MuiButtonBase-root  {
  color: black !important;
}

.videoWrapper {
  display: flex;
  justify-content: center;
}

.video {
  width: 853px;
  max-width: 100%;
  position: relative;
  border: 2px solid #D6B87C;
}

.video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video:before {
  content: '';
  display: block;
  padding-top: calc(56% + 4px);
}