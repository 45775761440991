.logo {
  margin: 0 auto;
  height: min(100%, 10rem);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.form-logo {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
