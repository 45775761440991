.primary-button {
  display: flex;
  color: #fff;
  background: var(--primary-color);
  font-weight: 600;
  padding: 0.75rem 1rem;
  box-shadow: var(--elev2);
  border-radius: 0.125rem;
  text-decoration: none;
  appearance: none;
  border: 0;
  outline: 0;
  font-family: inherit;
  font-size: 1rem;
  text-decoration: none;
  user-select: none;
  cursor: pointer;
  white-space: nowrap;
}