.error-page {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.title {
  font-size: 6rem;
  font-weight: bold;
  text-shadow: var(--primary-color) 0.25rem 0.25rem 0;
}

.desc {
  font-size: 1.25rem;
  margin-top: 0.5rem;
}

.btns {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.retry {
  margin-inline-end: 1.5rem;
}