.profile-media-section {
  width: 190px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}

.profile-picture-container {
  height: 148px;
  width: 148px;
  border: 5px solid transparent;
  border-radius: 100%;
  position: relative;
  margin: 20px auto;
}

.media {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.media img {
  width: 100%;
  position: static;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.image-size {
  padding-top: 10px;
}

.trash-icon {
  position: absolute;
  height: 30px;
  width: 30px;
  background: var(--gray_900);
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5), 0px 4px 6px rgba(0, 0, 0, 0.25);
  justify-content: center;
  z-index: 1;
  right: 14px;
  border-radius: 50%;
  cursor: pointer;
  visibility: hidden;
  transition: all 0.3s linear 0s;
}
.trash-icon svg {
  height: 14px;
  fill: var(--danger-color);
}

.profile-picture-container:hover .trash-icon {
  visibility: visible;
}

.prfile-btn-holder {
  position: absolute;
  bottom: -14px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.profile-button svg {
  width: 20px;
}

.tab-holder {
  margin-left: -1em;
  margin-right: -1em;
}

.container {
  display: flex;
  position: absolute;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.important {
  z-index: 10;
}
