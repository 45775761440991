.input-container {
  background-color: #2d2926;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.input {
  background-color: #2d2926;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  font-size: 20px;
  height: 56px;
}

.img {
  margin: 10px;
}
