.profile-media-section {
  width: 250px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}

.title {
  font-size: 35px !important;
}

.container figure {
  width: 100% !important;
  margin: 0;
}

.container {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 25px;
}

.add-container {
  margin-top: 30px;
  margin-left: 20px;
  cursor: pointer;
  display: flex;
}

.line {
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  height: 1px;
  margin: 30px 0px;
}

.line-less {
  border: 1px solid rgba(255, 255, 255, 0.18);
  width: 100%;
  height: 1px;
  margin-top: 20px;
  margin-left: 16px;
}

.trash-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.7;
  padding-top: 10px;
}

.eye-container {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 25px;
}

.middle-title {
  font-size: 22px;
  margin-top: 25px !important;
}

.vitals {
  font-weight: 600 !important;
  font-size: 16px !important;
  margin-top: 15px !important;
  margin-bottom: 10px !important;
  line-height: 28px;
  color: rgba(255, 255, 255, 0.8);
}

.subtitle {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  text-align: center;
  color: #bd9b60;
  margin-top: 5px;
}

.profile-picture-container {
  height: 148px;
  width: 148px;
  border: 2px solid var(--sand_500);
  border-radius: 100%;
  position: relative;
  margin: 20px auto;
}

.media {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media img {
  width: 100%;
  position: static;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.image-size {
  padding-top: 10px;
}

.trash-icon {
  position: absolute;
  height: 30px;
  width: 30px;
  background: var(--gray_900);
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5), 0px 4px 6px rgba(0, 0, 0, 0.25);
  justify-content: center;
  z-index: 1;
  right: 14px;
  border-radius: 50%;
  cursor: pointer;
  /* visibility: hidden; */
  transition: all 0.3s linear 0s;
}
.trash-icon svg {
  height: 14px;
  fill: var(--danger-color);
}

.profile-picture-container:hover .trash-icon {
  visibility: visible;
}

.athlete-container {
  width: 100%;
  margin-left: 20px;
  margin-top: 30px;
}
