@font-face {
  font-family: 'Gotham';
  src: local('Gotham'), url(./fonts/Gotham/Gotham-Light.otf) format('opentype');
}

@font-face {
  font-family: 'BourbonW00-Regular';
  src: local('BourbonW00-Regular'), url(./fonts/BourbonW00Regular/bourbon-w00-regular-webfont.woff2) format('woff2');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 100;
  src: local('Gotham'), url(./fonts/Gotham/Gotham-ThinItalic.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 300;
  src: local('Gotham'), url(./fonts/Gotham/Gotham-Thin.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 325;
  src: local('Gotham'), url(./fonts/Gotham/Gotham-XLight.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: local('Gotham'), url(./fonts/Gotham/Gotham-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'Gotham';
  font-weight: 700;
  src: local('Gotham'), url(./fonts/Gotham/Gotham-Black.otf) format('opentype');
}

@font-face {
  font-family: 'Barlow Condensed Light';
  font-weight: 300;
  font-display: block;
  font-style: normal;
  src: url(./fonts/BarlowCondensed/BarlowCondensed-Light.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Condensed Light Italic';
  font-weight: 300;
  font-display: block;
  font-style: italic;
  src: url(./fonts/BarlowCondensed/BarlowCondensed-LightItalic.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Condensed Regular';
  font-weight: 400;
  font-display: block;
  font-style: normal;
  src: url(./fonts/BarlowCondensed/BarlowCondensed-Regular.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Condensed Medium';
  font-weight: 500;
  font-display: block;
  font-style: normal;
  src: url(./fonts/BarlowCondensed/BarlowCondensed-Medium.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Condensed Semi Bold';
  font-weight: 600;
  font-display: block;
  font-style: normal;
  src: url(./fonts/BarlowCondensed/BarlowCondensed-SemiBold.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Condensed Bold';
  font-weight: 700;
  font-display: block;
  font-style: normal;
  src: url(./fonts/BarlowCondensed/BarlowCondensed-Bold.woff2) format('woff2');
}

@font-face {
  font-family: 'Barlow Condensed Extra Bold';
  font-weight: 800;
  font-display: block;
  font-style: normal;
  src: url(./fonts/BarlowCondensed/BarlowCondensed-ExtraBold.woff2) format('woff2');
}
