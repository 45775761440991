.profile-media-section {
  width: 250px;
  text-align: center;
  position: relative;
  margin: 0 auto;
}

.profile-picture-container {
  height: 148px;
  width: 148px;
  border: 2px solid var(--sand_500);
  border-radius: 100%;
  position: relative;
  margin: 20px auto;
}

.media {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.media img {
  width: 100%;
  position: static;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.image-size {
  padding-top: 10px;
}

.trash-icon {
  position: absolute;
  height: 30px;
  width: 30px;
  background: var(--gray_900);
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.5), 0px 4px 6px rgba(0, 0, 0, 0.25);
  justify-content: center;
  z-index: 1;
  right: 14px;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s linear 0s;
}
.trash-icon svg {
  height: 14px;
  fill: var(--danger-color);
}

.profile-picture-container:hover .trash-icon {
  visibility: visible;
}

.athlete-label-container {
  display: flex;
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  letter-spacing: 0.25px;
  color: rgba(255, 255, 255, 0.47);
}

.athlete-label {
  padding: 40px 20px 20px 20px;
  cursor: pointer;
}
