.imageOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(1, 1, 1, 0) 26.14%, rgba(1, 1, 1, 0.88) 100%);
}

.cardContent {
  position: absolute;
  bottom: 12px;
  left: 10px;
  right: 10px;
}

.imgContainer {
  height: 100%;
}

.imgContainer .css-1x63ihe {
  height: 100%;
}

.title {
  color: white;
  font-family: BourbonW00-Regular !important;
  font-weight: 400 !important;
}

.subTitle {
  color: white;
  font-weight: 325 !important;
  font-size: 14px !important;
  line-height: 12px !important;
  height: 12px !important;
  margin-top: 9px !important;
}

.container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.container a {
  flex-grow: 1;
  margin-bottom: 0;
}

.image {
  border-radius: 6px;
  width: 100%;
  display: block;
}
