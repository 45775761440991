.square {
  background-color: red;
  animation: appear 1s ease;
}

/* .infinite-list > div:nth-child(1) {
  height: auto !important;
} */

@keyframes appear {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes disappear {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
