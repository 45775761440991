@import '@fontsource/fira-sans';
@import './font.css';

:root {
  --sand_700: #e0b975;
  --sand_500: #bd9b60;
  --sand_400: #8a7144;

  --primary-color: var(--sand_700);

  --amber_700: #ffb330;
  --amber_500: #e0b975;
  --amber_400: #b58128;

  --charcoal_700: #423c37;
  --charcoal_500: #2d2926;
  --charcoal_400: #151312;
  --charcoal: #000000;

  --gray_900: #ffffff;
  --gray_700: #e0e3e3;
  --gray_500: #b1b3b3;
  --gray_400: #6b6b6b;

  --danger-color: #fe2c55;
  --error-red: #fe2c55;

  --top-h: 5rem;

  --z-nav: 1000;
  --z-top: 1200;

  --elev1: 0px 0.11px 0.42px 0px rgba(0, 0, 0, 0.03), 0px 0.27px 1px 0px rgba(0, 0, 0, 0.04),
    0px 0.5px 1.88px 0px rgba(0, 0, 0, 0.05), 0px 0.89px 3.35px 0px rgba(0, 0, 0, 0.06),
    0px 1.67px 6.27px 0px rgba(0, 0, 0, 0.07), 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
  --elev2: 0px 0.14px 0.44px 0px rgba(0, 0, 0, 0.03), 0px 0.33px 1.06px 0px rgba(0, 0, 0, 0.04),
    0px 0.63px 2px 0px rgba(0, 0, 0, 0.05), 0px 1.12px 3.57px 0px rgba(0, 0, 0, 0.06),
    0px 2.09px 6.68px 0px rgba(0, 0, 0, 0.07), 0px 5px 16px 0px rgba(0, 0, 0, 0.1);
  --elev3: 0px 0.19px 0.61px 0px rgba(0, 0, 0, 0.03), 0px 0.47px 1.46px 0px rgba(0, 0, 0, 0.04),
    0px 0.88px 2.75px 0px rgba(0, 0, 0, 0.05), 0px 1.56px 4.91px 0px rgba(0, 0, 0, 0.06),
    0px 2.92px 9.19px 0px rgba(0, 0, 0, 0.07), 0px 7px 22px 0px rgba(0, 0, 0, 0.1);
  --elev4: 0px 0.28px 0.75px 0px rgba(0, 0, 0, 0.03), 0px 0.67px 1.8px 0px rgba(0, 0, 0, 0.04),
    0px 1.25px 3.38px 0px rgba(0, 0, 0, 0.05), 0px 2.23px 6.03px 0px rgba(0, 0, 0, 0.06),
    0px 4.18px 11.28px 0px rgba(0, 0, 0, 0.07), 0px 10px 27px 0px rgba(0, 0, 0, 0.1);
  --elev5: 0px 0.42px 1.11px 1px rgba(0, 0, 0, 0.03), 0px 1px 2.66px 1px rgba(0, 0, 0, 0.04),
    0px 1.88px 5.01px 1px rgba(0, 0, 0, 0.05), 0px 3.35px 8.93px 1px rgba(0, 0, 0, 0.06),
    0px 6.27px 16.71px 1px rgba(0, 0, 0, 0.07), 0px 15px 40px 1px rgba(0, 0, 0, 0.1);
  --elev6: 0px 0.75px 1.38px 2px rgba(0, 0, 0, 0.03), 0px 1.8px 3.33px 2px rgba(0, 0, 0, 0.04),
    0px 3.38px 6.26px 2px rgba(0, 0, 0, 0.05), 0px 6.03px 11.17px 2px rgba(0, 0, 0, 0.06),
    0px 11.28px 20.89px 2px rgba(0, 0, 0, 0.07), 0px 27px 50px 2px rgba(0, 0, 0, 0.1);
}

* {
  box-sizing: border-box;
  min-width: 0;
  min-height: 0;
}

html,
body,
#root,
.App {
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  background: var(--charcoal);
  color: var(--gray_900);
  font-family: 'Gotham', Arial Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: var(--amber_500);
  text-decoration: none;
}

.thin-scroll {
  scrollbar-width: thin;
}

.thin-scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.thin-scroll::-webkit-scrollbar-track {
  background: #ddd;
}

.thin-scroll::-webkit-scrollbar-thumb {
  background: #aaa;
}

.thin-scroll::-webkit-scrollbar-thumb:hover {
  background: #888;
}

.slider-dots .slick-dots {
  list-style: none;
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.dot-indicator {
  display: inline-block;
  height: 8px;
  width: 8px;
  background: var(--gray_400);
  margin: 0 3px;
  border-radius: 100%;
}

.slider-dots .slick-dots li.slick-active .dot-indicator {
  background: var(--gray_900);
}
.slider-dots.theme-light .slick-dots li.slick-active .dot-indicator {
  background: var(--amber_500);
}
