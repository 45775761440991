.loading {
  padding-top: 2rem;
  font-size: 6vw;
  color: #e0b975;
  /* font-family: 'BourbonW00-Regular'; */
}

.logo {
  width: 35vw;
}
