.main {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  flex: 1;
}

.nav {
  display: flex;
  flex: none;
}

.content {
  min-width: 0;
  min-height: 0;
  flex: 1;
  display: flex;
  overflow-x: hidden;
}
